import React from "react";
import { useStore } from "@nanostores/react";
import type { NavItemType } from "@wbly/data-storefront";
import type { Store } from "nanostores";
import { iconsaxUser } from "@wbly/tokens/icon";
import { Icon } from "@wbly/ui/Icon";
import { $navPanel, triggerNavPanel } from "../../stores/ui.store";
import { CountryPicker } from "../CountryPicker";
import { trackMenuOpen, trackMenuItemSelect } from "../Header/track-nav-events";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  QuestionIcon,
} from "../Header/Icons";
import styles from "./Nav.module.css";
import {
  $nav,
  setItemLevel,
  resetNav,
  setPanelTitle,
  type ItemLevelsParams,
  type StoreType,
  setMobileIndex,
  setSlideLevel,
  setDesktopLevel,
} from "./nav.store";
import { useNav } from "./useNav";

type SubMenuProps = {
  items: NavItemType[];
  enterAction?: ({ level, type, items }: ItemLevelsParams) => void;
  leaveAction?: () => void;
  title?: string;
  nextLevel?: 2 | 3;
  type?: "mobile";
};

const {
  accountIcon,
  mainNav,
  level1,
  level3,
  navPanel,
  submenu,
  submenuContainer,
  navPanelContainer,
  navPanelWrapper,
  navPanelTitle,
  backButton,
  closeContainer,
  footer,
  supportLink,
  supportLinkLabel,
} = styles;

const SubMenu = (props: SubMenuProps) => {
  const { items, enterAction, leaveAction, title, nextLevel, type } = props;

  return (
    <ul className={submenu}>
      {title && <h4>{title}</h4>}

      {items?.map((subItem, index) => (
        <li
          key={subItem.id}
          data-id={subItem.id}
          data-testid={`nav-submenu-item-${subItem?.label?.replace(/\s+/g, "-")?.toLocaleLowerCase()}`}
          className={level3}
          onMouseEnter={() => {
            if (enterAction && nextLevel) {
              enterAction({
                level: nextLevel,
                items: items[index].submenu,
                type,
              });
            }
          }}
          onMouseLeave={() => leaveAction?.()}
        >
          {subItem.url || subItem.link ? (
            <a
              onClick={() => {
                trackMenuItemSelect(
                  "nav-v3",
                  subItem.label,
                  subItem.url || subItem.link
                );
              }}
              href={subItem.url || subItem.link}
            >
              {subItem.label}
            </a>
          ) : (
            <span>{subItem.label}</span>
          )}
        </li>
      ))}
    </ul>
  );
};

const NavItem = ({ item }) => {
  return (
    <li
      data-testid={`nav-item-${item.label.replace(/\s+/g, "-").toLocaleLowerCase()}`}
      data-id={item.id}
      onMouseEnter={() => {
        resetNav();
        setDesktopLevel(2, Number(item.id));

        if (item.submenu.length === 0) {
          triggerNavPanel("close");
          return;
        }

        triggerNavPanel("open");
      }}
    >
      {item.url || item.link ? (
        <a href={item.url || item.link}>{item.label}</a>
      ) : (
        <span>{item.label}</span>
      )}
    </li>
  );
};

const CloseButton = () => (
  <div className={closeContainer}>
    <button
      onClick={() => {
        resetNav();
        triggerNavPanel("close");
        setSlideLevel(0);
      }}
    >
      <Close />
    </button>
  </div>
);

export const Nav = ({
  menuItems,
  navFooter,
  currentCountry,
  countries,
  changeCountryLabel,
  otherCountryLabel,
  navGlobalContent,
}) => {
  const { isOpen } = useStore($navPanel);
  const {
    isDesktop,
    mobile_l1,
    mobile_l2,
    mobile_l3,
    panelTitle,
    currentMobileIndex,
    slideLevel,
    navItems,
    l2,
  } = useStore($nav as Store<StoreType>);

  const { memoItems, panelOffset } = useNav({
    menuItems,
    navFooter,
  });

  return (
    <>
      <nav
        className={mainNav}
        onMouseLeave={() => {
          triggerNavPanel("close");
        }}
      >
        <ul className={level1}>
          {memoItems.map((item) => (
            <NavItem key={item.id} item={item} data-id={item.id} />
          ))}
        </ul>
      </nav>

      <div
        className={navPanelWrapper}
        onMouseEnter={() => triggerNavPanel("open")}
        data-mobile-slide={isOpen}
        onMouseLeave={() => {
          isDesktop && triggerNavPanel("close");
        }}
        style={{
          top: isDesktop && `${panelOffset}px`,
        }}
      >
        <CloseButton />

        {/* Desktop submenu L1/L2/L3 */}
        {isDesktop &&
          navItems.map((item, index) => (
            <div
              className={navPanelContainer}
              data-desktop-visible={l2 === index + 1}
              key={item.id}
            >
              <SubMenu
                items={item.submenu}
                nextLevel={3}
                enterAction={setItemLevel}
              />

              <div
                className={navPanel}
                style={{
                  transform: `translateX(calc((280px * ${slideLevel} * -1))`,
                }}
              >
                <div className={submenuContainer}>
                  {item.submenu.map((submenuL2) => {
                    if (submenuL2.submenu.length > 0) {
                      return submenuL2.submenu.map((submenuL3) => {
                        return (
                          <SubMenu
                            items={submenuL3.items}
                            title={submenuL3.label}
                            key={submenuL3.id}
                            data-id={submenuL3.id}
                          />
                        );
                      });
                    }
                  })}
                </div>
              </div>
            </div>
          ))}

        {/* Mobile submenu L1/L2/L3 */}
        {!isDesktop && (
          <div className={navPanelContainer}>
            <div
              className={navPanel}
              style={{
                transform: `translateX(calc((280px * ${slideLevel} * -1))`,
              }}
            >
              <div className={submenuContainer}>
                <ul>
                  {mobile_l1.map((item) => {
                    return (
                      <li
                        key={item.id}
                        className={level1}
                        data-id={item.id}
                        data-testid={`nav-item-${item.label.replace(/\s+/g, "-").toLocaleLowerCase()}`}
                        data-h1
                      >
                        {item.submenu.length > 0 ? (
                          <button
                            onClick={() => {
                              setItemLevel({
                                level: 2,
                                items: item.submenu,
                                type: "mobile",
                              });

                              setPanelTitle(item.label);
                              setSlideLevel(1);
                              trackMenuOpen("nav-v3", item.label, 2, item.id);
                            }}
                          >
                            {item.label}
                            <ChevronRight />
                          </button>
                        ) : (
                          <a
                            data-h1
                            onClick={() => {
                              trackMenuItemSelect(
                                "nav-v3",
                                item.label,
                                item.url || item.link
                              );
                            }}
                            href={item.url || item.link}
                          >
                            {item.label}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div
              className={navPanel}
              style={{
                transform: `translateX(calc((280px * ${slideLevel} * -1))`,
              }}
            >
              {mobile_l2.length > 0 && (
                <>
                  <button
                    className={backButton}
                    onClick={() => {
                      setItemLevel({ level: 2, items: [], type: "mobile" });
                      setMobileIndex(0);
                      setSlideLevel(0);
                    }}
                  >
                    <ChevronLeft />
                    {navGlobalContent.navAllTranslation}
                  </button>
                  <h2 className={navPanelTitle}>{panelTitle}</h2>
                  <ul>
                    {mobile_l2.map((item) => {
                      const submenuItem = item.submenu;

                      const submenuLinks = submenuItem.map((subItem, index) => {
                        return (
                          <li key={subItem.id} data-id={subItem.id}>
                            <button
                              onClick={() => {
                                setItemLevel({
                                  level: 3,
                                  items: submenuItem,
                                  type: "mobile",
                                });

                                setMobileIndex(index);
                                setSlideLevel(2);
                                trackMenuOpen(
                                  "nav-v3",
                                  subItem.label,
                                  3,
                                  subItem.id
                                );
                              }}
                            >
                              {subItem.label}
                              <ChevronRight />
                            </button>
                          </li>
                        );
                      });

                      return (
                        <>
                          <li key={item.id} data-id={item.id}>
                            <a
                              onClick={() => {
                                trackMenuItemSelect(
                                  "nav-v3",
                                  item.label,
                                  item.url || item.link
                                );
                              }}
                              href={item.link || item.url}
                            >
                              {item.label}
                            </a>
                          </li>

                          {submenuLinks}
                        </>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>

            <div
              className={navPanel}
              style={{
                transform: `translateX(calc((280px * ${slideLevel} * -1))`,
              }}
            >
              {mobile_l3?.length > 0 && (
                <>
                  <button
                    className={backButton}
                    onClick={() => {
                      setItemLevel({ level: 3, items: [], type: "mobile" });
                      setMobileIndex(2);
                      setSlideLevel(1);
                    }}
                  >
                    <ChevronLeft />
                    {panelTitle}
                  </button>

                  <h2 className={navPanelTitle}>
                    {mobile_l3[currentMobileIndex].label}
                  </h2>
                  <SubMenu items={mobile_l3[currentMobileIndex].items} />
                </>
              )}
            </div>
          </div>
        )}

        <div className={footer}>
          <a
            href={navFooter.accountLabel.url}
            aria-label="account"
            className={supportLink}
          >
            <Icon
              className={accountIcon}
              href={iconsaxUser}
              size="medium"
              title="account"
            />
            <span className={supportLinkLabel}>
              {navFooter.accountLabel.label}
            </span>
          </a>
          <a
            href={navFooter.otherLinkLabel.url}
            aria-label="help"
            className={supportLink}
          >
            <QuestionIcon />
            <span className={supportLinkLabel}>
              {navFooter.otherLinkLabel.label}
            </span>
          </a>
          <CountryPicker
            currentCountry={currentCountry}
            countries={countries}
            changeCountryLabel={changeCountryLabel}
            otherCountryLabel={otherCountryLabel}
          />
        </div>
      </div>
    </>
  );
};
